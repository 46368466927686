// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Braah+One&family=Oswald&display=swap');

/*--BASED--*/
.classfontbrand {
	font-family: 'Braah One', sans-serif;
	font-size:35px;
	font-weight: bolder;
	color: #405d0b;
	margin-bottom: -9px;
}
.classfontcolor { color: #405d0b; }
.classheadertop {
	background: white url(../img/bg_header_top.png) top left repeat-x
}
.classbglogintop {
	position: fixed;
	bottom:0;
	left:0;
	width:100%;
	height:100%;
	transform: rotate(180deg);
	background-color: transparent;
	background-position: bottom right;
	background-repeat: no-repeat;
	opacity: 0.2;
	/*border:1px solid red;/**/
}
.classbgloginwatermark {
	display:none;
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color: transparent;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 400px 400px;
	opacity: 0.075;
	z-index: -1;
	/*border:1px solid red;/**/
}
.classcursorpointer {
	cursor: pointer;
	text-decoration: none;
}
.classbgdisabled {
	background-color: #f0f0f0 !important;
}
.classfakelink {
	cursor: pointer;
	border: 1px solid #FC3A1F55;
	padding: 3px;
	border-radius: 3px;
	vertical-align:middle;
}
.classpetunjuk {
	color: #339900;
	font-style:italic;
	font-size:90%;
	padding-left:15px;
}
.classbgwhite {
	background-color: white;
}
.classcardbg { background-color:#ffffff99; }
.classfontsmaller {
	font-size: smaller;
}
.classToTop {
	cursor:pointer;
	position:fixed;
	min-width: 30px;
	z-index:1030;
	bottom: 35px;
	right:20px;
	opacity:1;
	transition: width 0.1s, height 0.1s, opacity 0.1s 0.1s;
}
.classrowselect {
	background-color: rgba(#FEE1B6, 0.3) !important;
}
.classposmidcenter {
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1060;
}
.classtombol {
	font-family: 'Oswald', sans-serif;
	font-size: larger;
	color: #F9E79F !important;
	border-radius: 10px !important;
	border-style:none !important;
	background-image: linear-gradient(#DCDCDC,#405d0b);
	cursor: pointer;
}
.classtombol:disabled {
	color: rgba(black,0.6) !important;
	background-image: linear-gradient(#f0f0f0,gray);
}
.classbordertmp {
	border: 1px solid red !important;/**/
} 
.classborderbottom {
	border-bottom: 1px dotted #DCDCDC !important;
} 
/*--END BASED--*/

/*--NOTA HTML--*/
.classtabelhtml {
	width: 100%;
	margin: 0 auto;
	border-spacing: 0;
	padding: 0;
	text-align: left;
	display: table;
}
.classtabelhtml tbody td {
	padding: 0; 
	overflow-wrap: break-word; word-wrap: break-word; -ms-word-break: break-all;
	word-break: break-all; word-break: break-word;
	-ms-hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto;
	hyphens: auto;
}
.classnotahr {
	border-top-style: dashed;
	border-top-width: 1px;
	border-left: none;
	border-right: none;
	border-bottom: none;
	height: 1px;
}
/*--END NOTA HTML--*/

table#idtabellaporan tfoot tr {
	background-color: #145A32;
	color: white;
	border-top: 4px double white;
	font-weight:bolder;
}

/*--IKON--*/
.classverticalmiddle {
    vertical-align: middle;
}
.classikontabel {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    margin-right: 3px;
}
.classikon {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    margin-right: 3px;
}
.classikonbox {
	border:1px solid #836FFD;
	border-radius: 5px;
	background-color: rgba(#836FFD,0.1);
	padding: 2px;
	margin-right: 3px;
	vertical-align:middle;
	color: #2300FF;
}
.classikonavatar {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
    background-color: #DCDCDC !important;
	border: 1px dotted white;
}
.classikonlogowhite {
	background: transparent url(../img/icon_logowhite.png) center center no-repeat;
	background-size: cover;
}
.classikonlogo {
	background: transparent url(../img/icon_logo.png) center center no-repeat;
	background-size: cover;
}
.classikontambah {
	background: transparent url(../img/icon_tambah.png) center center no-repeat;
	background-size: cover;
}
.classikonedit {
	background: transparent url(../img/icon_edit.png) center center no-repeat;
	background-size: cover;
}
.classikonhapus {
	background: transparent url(../img/icon_hapus.png) center center no-repeat;
	background-size: cover;
}
.classikoncetak {
	background: transparent url(../img/icon_cetak.png) center center no-repeat;
	background-size: cover;
}
.classikonexcel {
	background: transparent url(../img/icon_excel.png) center center no-repeat;
	background-size: cover;
}
.classikonreset {
	background: transparent url(../img/icon_reset.png) center center no-repeat;
	background-size: cover;
}
.classikondown {
	background: transparent url(../img/icon_down.png) center center no-repeat;
	background-size: cover;
}
.classikondashboard {
	background: transparent url(../img/icon_dashboard.png) center center no-repeat;
	background-size: cover;
}
/*--IKON--*/


/*--TAMBAHAN--*/
.classblinking {
	-webkit-animation: blink 1s step-end infinite;
	-moz-animation: blink 1s step-end infinite;
	-o-animation: blink 1s step-end infinite;
	animation: blink 1s step-end infinite;
}
@-webkit-keyframes blink {
	67% { opacity: 0 }
}
@-moz-keyframes blink {
	67% { opacity: 0 }
}
@-o-keyframes blink {
	67% { opacity: 0 }
}
@keyframes blink {
	67% { opacity: 0 }
}

a:hover.classnounderline {
	text-decoration: none !important;
}
/*--END TAMBAHAN--*/